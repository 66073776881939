import React, { useEffect, useCallback, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import pdfMap from '../pdf/pdfMap'
import '../stylesheets/pdfarticle.scss'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'

const SampleTrialPdf = ({ selectedLocale, strings }) => {
  const [isValidArticle, setIsValidArticle] = useState(true)
  const [pdfFile, setPdfFile] = useState(null)
  const [_numPages, setNumPages] = useState(null)
  const [pdfModule, setPdfModule] = useState(null)

  const isIos = navigator.userAgent.includes('iPhone OS')

  const loadPdf = (openPdf = false) => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const article = urlParams.get('article')
    const articleFileName = `${article}__${selectedLocale.toUpperCase()}`
    const pdf = pdfMap[articleFileName]
    if (pdf) {
      if (openPdf) window.location = pdf
      else setPdfFile(pdf)
    } else {
      setIsValidArticle(false)
    }
  }

  useEffect(() => {
    loadPdf()
  }, [selectedLocale])

  if (isIos) {
    loadPdf(isIos)
  }

  import('react-pdf/dist/esm/entry.webpack').then(module => {
    setPdfModule(module)
  })

  const renderPages = useMemo(() => {
    if (_numPages && pdfModule) {
      const { Page } = pdfModule
      const pageArr = []
      for (let i = 1; i <= _numPages; i++) {
        pageArr.push(i)
      }
      return pageArr.map(page => {
        return (
          <Page
            key={`article-page-${page}`}
            pageNumber={page}
            renderAnnotationLayer
            renderTextLayer={false}
            width={300}
            devicePixelRatio={2}
          />
        )
      })
    }
    return null
  }, [_numPages, pdfModule])

  const renderPdf = useCallback(() => {
    if (pdfModule) {
      const { Document } = pdfModule
      return (
        <Document
          file={pdfFile}
          onLoadSuccess={({ numPages }) => {
            setNumPages(numPages)
          }}>
          {renderPages}
        </Document>
      )
    }
    return null
  }, [pdfFile, _numPages, pdfModule])

  return (
    <div className='pdf-article'>
      {pdfFile ? (
        renderPdf()
      ) : (
        <>
          {isValidArticle
            ? strings.redirect_dl_message
            : strings.invalid_article}
        </>
      )}
    </div>
  )
}

SampleTrialPdf.propTypes = {
  selectedLocale: PropTypes.string,
  strings: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  ),
}

export default SampleTrialPdf
