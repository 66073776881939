import React from 'react'
import PropTypes from 'prop-types'
import '../styles/d3/rectMarker.scss'

const RectMarker = ({ color = '' }) => {
  return <div className={`rect-marker ${color}`} />
}

RectMarker.propTypes = {
  color: PropTypes.string,
}

export default RectMarker
