import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import '../stylesheets/main.css'
import pdf from '../pdf/Trial1.pdf'

const Trial1PdfPage = ({ strings }) => {
  useEffect(() => {
    window.location = pdf
  }, [])
  return <div className='v3_2'>YOU WILL BE REDIRECTED SHORTLY.</div>
}

Trial1PdfPage.propTypes = {
  strings: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  ),
}

export default Trial1PdfPage
