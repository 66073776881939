import React from 'react'
import PropTypes from 'prop-types'
import '../stylesheets/main.css'

const TrialPage1 = ({ strings }) => {
  return (
    <div className='v3_2'>
      <div className='v7_48' />
      <div className='v7_51' />
      <div className='v7_53' />
      <div className='v7_49' />
      <div className='v7_40' />
      <div className='v9_98' />
      <div className='v9_99' />
      <span className='v6_16'>
        Attending all your study visits and the following the instructions
        provided by your study team will ensure that your health and safety are
        carefully monitored and that they understand how the study drug is
        working.
      </span>
      <span className='v6_17'>
        We greatly appreciate you joining us on this journey to help us
        understand the effects of NTLA-2002 when given to people with HAE in the
        hopes of helping you and others in the future. This would not be
        possible without you.
      </span>
      <div className='v8_63' />
      <div className='name' />
      <div className='name' />
      <div className='name' />
      <div className='v8_60' />
      <span className='v6_12'>What to expect from each study </span>
      <div className='v7_41' />
      <span className='v6_13'>
        Important reminders regarding your participation
      </span>
      <div className='v9_89'>
        <div className='v9_90' />
        <div className='v9_91' />
        <div className='v9_92' />
        <div className='v9_93' />
      </div>
      <span className='v6_14'>Recording entries into your Attack Diary </span>
      <div className='v7_46' />
      <span className='v6_15'>Completing questionnaires </span>
      <span className='v10_117'>Completing questionnaires </span>
      <div className='v16_61' />
      <span className='v10_119'>
        The study team showed you how to access the Attack Diary to record the
        details about the HAE attacks you experience throughout the study. It is
        important that you keep this up to date as your study team will want to
        review the information.
      </span>
      <span className='v6_8'>The HAE Attack Diary</span>
      <span className='v6_10'>The ITL-2002-CL-001 Study App</span>
      <span className='v6_11'>
        This app will serve as a companion to help guide you through the study.
        It will provide information for the following:
      </span>
      <span className='v6_7'>
        After completing the Screening Period and determining that you qualify
        to enroll in the study, you will be expected to participate in 32 study
        visits throughout Phase 1 and/or Phase 2 of the study, so that your
        study team can carefully monitor your health and safety. Some study
        visits and assessments may be done in your home or at a local physicians
        office.
      </span>
    </div>
  )
}

TrialPage1.propTypes = {
  strings: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  ),
}

export default TrialPage1
