import { connect } from 'react-redux'
import { selectLanguage } from 'store/locales'
import Trial1Page from '../components/Trial1Page'

const mapStateToProps = ({ strings }) => {
  return {
    strings,
  }
}

const mapDispatchToProps = {
  selectLanguage,
}

export default connect(mapStateToProps, mapDispatchToProps)(Trial1Page)
