import AnniversaryPage from './containers/ArticleSampleContainer'

export default store => {
  return {
    path: 'sample-article',
    exact: true,
    getComponent(nextState, cb) {
      require.ensure([], () => {
        cb(null, AnniversaryPage, 'articles')
      })
    },
  }
}
