export const OPEN_DRAWER = 'OPEN_DRAWER'
export const CLOSE_DRAWER = 'CLOSE_DRAWER'

const openDrawer = drawerProps => {
  return {
    type: OPEN_DRAWER,
    drawerProps,
  }
}

const closeDrawer = () => {
  return {
    type: CLOSE_DRAWER,
  }
}

export default function drawerReducer(state = { open: false }, action) {
  switch (action.type) {
    case OPEN_DRAWER:
      return Object.assign({ open: true, className: 'universal-drawer' }, action.drawerProps)
    case CLOSE_DRAWER:
      return { open: false }
    default:
      return state
  }
}

export const drawerActions = {
  openDrawer,
  closeDrawer,
}
