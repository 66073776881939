import { connect } from 'react-redux'
import { selectLanguage } from 'store/locales'
import SampleTrial2PdfPage from '../components/SampleTrial2PdfPage'

const mapStateToProps = ({ strings }) => {
  return {
    strings,
  }
}

const mapDispatchToProps = {
  selectLanguage,
}

export default connect(mapStateToProps, mapDispatchToProps)(SampleTrial2PdfPage)
