import React from 'react'
import ReactDOM from 'react-dom'
import smoothscroll from 'smoothscroll-polyfill'
import createStore from 'store/createStore'
import { browserHistory, Router, Route } from 'react-router'
import { Provider } from 'react-redux'
import CoreLayout from './layouts/CoreLayout/CoreLayout'
import './styles/main.scss'
import '../node_modules/font-awesome/css/font-awesome.min.css'
import './utils/object'

// ========================================================
// Store Instantiation
// ========================================================

const store = createStore(
  window.___INITIAL_STATE__,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
)

// ========================================================
// Render Setup
// ========================================================
const MOUNT_NODE = document.getElementById('root')
smoothscroll.polyfill()

let render = () => {
  const routes = require('./routes/index').default(store)
  ReactDOM.render(
    <Provider store={store}>
      <div style={{ height: '100%' }}>
        <Router
          onUpdate={() => window.scrollTo(0, 0)}
          history={browserHistory}
          children={routes}
        />
        {/* <Router onUpdate={() => window.scrollTo(0, 0)} history={browserHistory}>
          <Route exact path='/' component={CoreLayout} />
        </Router> */}
      </div>
    </Provider>,
    MOUNT_NODE,
  )
}

// This code is excluded from production bundle
if (__DEV__) {
  if (module.hot) {
    // Development render functions
    const renderApp = render
    const renderError = error => {
      const RedBox = require('redbox-react').default

      ReactDOM.render(<RedBox error={error} />, MOUNT_NODE)
    }

    // Wrap render in try/catch
    render = () => {
      try {
        renderApp()
      } catch (error) {
        console.error(error)
        renderError(error)
      }
    }

    // Setup hot module replacement
    module.hot.accept('./routes/index', () =>
      setImmediate(() => {
        ReactDOM.unmountComponentAtNode(MOUNT_NODE)
        render()
      }),
    )
  }
}

// ========================================================
// Go!
// ========================================================
render()
