import ArticleEightPage from './containers/ArticleEightContainer'

export default store => {
  return {
    path: '8',
    exact: true,
    getComponent(nextState, cb) {
      require.ensure([], () => {
        cb(null, ArticleEightPage, 'articles')
      })
    },
  }
}
