import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Container, RectMarker } from 'components/UIElements'
import { COLORS } from 'utils/constants'

const containerImg1 = require('../../../assets/go42784/6/energy illo 1@3x.png')
const containerImg2 = require('../../../assets/go42784/6/energy illo 2@3x.png')
const containerImg3 = require('../../../assets/go42784/6/energy illo 3@3x.png')
const containerImg4 = require('../../../assets/go42784/6/energy illo 4@3x.png')
const containerImg5 = require('../../../assets/go42784/6/group 99@3x.png')

const containerObj = [
  { title: '', img: containerImg1, text: '' },
  { title: '', img: containerImg2, text: '' },
  { title: '', img: containerImg3, text: '' },
  { title: '', img: containerImg4, text: '' },
  { title: '', img: containerImg5, text: '' },
]

const AssetsPage = ({ strings }) => {
  const [containers, setContainers] = useState(fillContainers(strings))

  function fillContainers(strs) {
    for (let i = 0; i < containerObj.length; i++) {
      const element = containerObj[i]
      element.title = strs[`article.6.energy.${3 + 2 * i}-h2`]
      element.text = strs[`article.6.energy.${4 + 2 * i}-p`]
    }
    return containerObj
  }

  return (
    <div className='article page pink-2'>
      <RectMarker color={COLORS.pink3} />
      <h1>{strings['article.6.energy.1-h1']}</h1>
      <section>
        <p>{strings['article.6.energy.2-p']}</p>
      </section>
      {containers.map((container, i) => {
        return (
          <Container key={i}>
            <h2>{container.title}</h2>
            <img
              className='mb10 dynamic-width'
              alt='energy img'
              src={container.img}
            />
            <p>{container.text}</p>
          </Container>
        )
      })}
    </div>
  )
}

AssetsPage.propTypes = {
  strings: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  ),
}

export default AssetsPage
