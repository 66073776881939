import { authActions } from 'store/user'
import { actions as notyActions } from 'layouts/ErrorBox'
import { loadingActions } from 'store/loader'

const _getDefaultMessages = (status, strings) => {
  switch (status) {
    case 401:
      return strings.error_401
    case 403:
      return strings.error_403
    case 404:
      return strings.error_404
    case 500:
    default:
      return strings.error_500
  }
}

// default fail behavior is to display _defaultMessages
// customFail will replace all default behavior
const _defaultFail = (
  response,
  resContent,
  customFail,
  failMessage,
  skipNotyFail,
) => {
  const content = JSON.parse(resContent)
  return (dispatch, getState) => {
    const { strings } = getState()
    let errMessage =
      failMessage || _getDefaultMessages(response.status, strings)
    errMessage =
      errMessage ||
      content.message ||
      `${response.status} Error: ${response.statusText}.`
    if (response.status === 401 || response.status === 403) {
      dispatch(authActions.logout())
      dispatch(
        notyActions.showError({
          text: `${
            response.status === 401
              ? _getDefaultMessages(401, strings)
              : _getDefaultMessages(403, strings)
          }`,
          timeout: 5000,
          killer: true,
        }),
      )
    }
    if (customFail) {
      dispatch(customFail(response, content.message))
    } else if (!skipNotyFail) {
      dispatch(notyActions.showError({ text: errMessage, timeout: 2000 }))
    }
  }
}

// default success behavior
const _getDefaultSuccess = successMessage => {
  // return () => {
  //   return dispatch => {
  //     if (successMessage)
  //       dispatch(notyActions.showSuccess({ text: successMessage }))
  //   }
  // }
}

// validate the session and returns a promise

const handleInvalidSession = (dispatch, err) => {
  dispatch(authActions.logout())
  dispatch(
    notyActions.showError({ text: err.message, timeout: 5000, killer: true }),
  )
}

export const validateSession = (dispatch, user, callback, strings) => {
  let errMessage
  return new Promise((resolve, reject) => {
    if (!user.token || !user.exp) {
      errMessage = _getDefaultMessages(401, strings)
    } else if (user.exp * 1000 < Date.now()) {
      errMessage = strings.error_session_expired
    }
    if (errMessage) throw new Error(errMessage)
    else resolve()
  })
    .then(callback)
    .catch(err => handleInvalidSession(dispatch, err))
}

const request = ({
  method = 'GET',
  url,
  body,
  headerOptions,
  contentType = 'application/json',
  success,
  skipNotySuccess,
  skipNotyFail = false,
  fail,
  failMessage,
  successMessage,
  catchMessage,
  forceLoader = false,
  authRequired = true,
  resType = 'json', // currently supports only blob or json responses
  hasLoader = false,
}) => {
  return (dispatch, getState) => {
    if (hasLoader) dispatch(loadingActions.startLoader(forceLoader))
    const resolveLoader = () => {
      if (hasLoader) dispatch(loadingActions.stopLoader(forceLoader))
    }
    const { user, strings } = getState()

    let isZip = false
    const callback = () => {
      // track response
      let response

      // establish default success behavior (print success message if provided)
      success = success || _getDefaultSuccess(successMessage)

      // setup headers
      const headers = {
        Authorization: user.token,
      }
      if (contentType) headers['Content-Type'] = contentType
      Object.assign(headers, headerOptions)

      if (contentType === 'multipart/form-data') {
        delete headers['Content-Type']
      }

      // make fetch request
      return fetch(url, { method, headers, body })
        .then(res => {
          response = res
          if (res.ok) {
            if (res.url.includes('.zip')) isZip = true
            if (!isZip) {
              if (resType === 'json') return res.json()
              if (resType === 'blob') return res.blob()
            }
            return Promise.resolve(res.blob())
          }
          return res.text()
        })
        .then(resContent => {
          let nextStep
          if (response.ok) {
            if (!skipNotySuccess && successMessage)
              dispatch(
                notyActions.showSuccess({
                  text: successMessage,
                  timeout: 2000,
                }),
              )
            nextStep = success(resContent)
          } else {
            nextStep = dispatch(
              _defaultFail(
                response,
                resContent,
                fail,
                failMessage,
                skipNotyFail,
              ),
            )
          }
          resolveLoader()
          return nextStep || true // return default true to handle conditional promise chaining
        })
        .catch(err => {
          if (catchMessage)
            dispatch(
              notyActions.showError({ text: catchMessage, timeout: 2000 }),
            )
          resolveLoader()
          console.log(err)
        })
    }
    if (!authRequired) return callback()
    return validateSession(dispatch, user, callback, strings)
  }
}

export default request
