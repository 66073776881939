import { setSubroute } from 'store/navbar'
import ArticleOneRoute from './routes/ArticleOne'
import ArticleTwoRoute from './routes/ArticleTwo'
import ArticleThreeRoute from './routes/ArticleThree'
import ArticleFourRoute from './routes/ArticleFour'
import ArticleFiveRoute from './routes/ArticleFive'
import ArticleSixRoute from './routes/ArticleSix'
import ArticleSevenRoute from './routes/ArticleSeven'
import ArticleEightRoute from './routes/ArticleEight'
import ArticleNineRoute from './routes/ArticleNine'
import ArticleTenRoute from './routes/ArticleTen'
import AnniversaryRoute from './routes/SampleAnniversary'
import ArticleSample from './routes/SampleArticle'
import ArticleVisit from './routes/SampleVisit'
import ArticleTrial1 from './routes/SampleTrial1'
import ArticleTrial2 from './routes/SampleTrial2'
import ArticleTrial1Pdf from './routes/SampleTrial1PdfSample'
import ArticleTrial2Pdf from './routes/SampleTrial2PdfSample'
import PdfArticle from './routes/PdfArticle'

export default store => {
  return {
    path: 'articles',
    exact: true,
    getChildRoutes(location, cb) {
      store.dispatch(setSubroute('articles'))
      cb(null, [
        ArticleOneRoute(store),
        ArticleTwoRoute(store),
        ArticleThreeRoute(store),
        ArticleFourRoute(store),
        ArticleFiveRoute(store),
        ArticleSixRoute(store),
        ArticleSevenRoute(store),
        ArticleEightRoute(store),
        ArticleNineRoute(store),
        ArticleTenRoute(store),
        ArticleTenRoute(store),
        AnniversaryRoute(store),
        ArticleSample(store),
        ArticleVisit(store),
        ArticleTrial1(store),
        ArticleTrial2(store),
        ArticleTrial1Pdf(store),
        ArticleTrial2Pdf(store),
        PdfArticle(store),
      ])
    },
  }
}
