import ArticlesRoute from './routes/Articles'

export default store => {
  return {
    path: 'client',
    exact: true,
    getChildRoutes(location, cb) {
      cb(null, [ArticlesRoute(store)])
    },
  }
}
