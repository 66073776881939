import { connect } from 'react-redux'
import { selectLanguage } from 'store/locales'
import ArticleSeven from '../components/ArticleSeven'

const mapStateToProps = ({ strings }) => {
  return {
    strings,
  }
}

const mapDispatchToProps = {
  selectLanguage,
}

export default connect(mapStateToProps, mapDispatchToProps)(ArticleSeven)
