const START_LOADING = 'START_LOADING'
const STOP_LOADING = 'STOP_LOADING'
const START_BATTERY_LOADING = 'START_BATTERY_LOADING'
const STOP_BATTERY_LOADING = 'STOP_BATTERY_LOADING'
const START_PHONECONTACTS_LOADING = 'START_PHONECONTACTS_LOADING'
const STOP_PHONECONTACTS_LOADING = 'STOP_PHONECONTACTS_LOADING'
const START_LOCATION_LOADING = 'START_LOCATION_LOADING'
const STOP_LOCATION_LOADING = 'STOP_LOCATION_LOADING'
const START_STEPCOUNTER_LOADING = 'START_STEPCOUNTER_LOADING'
const STOP_STEPCOUNTER_LOADING = 'STOP_STEPCOUNTER_LOADING'
const START_APPSINSTALLED_LOADING = 'START_APPSINSTALLED_LOADING'
const STOP_APPSINSTALLED_LOADING = 'STOP_APPSINSTALLED_LOADING'
const START_SCREENTIME_LOADING = 'START_SCREENTIME_LOADING'
const STOP_SCREENTIME_LOADING = 'STOP_SCREENTIME_LOADING'

let forcedLoader = false

export const startLoader = (forced = false, type = null) => {
  if (type) {
    return {
      type: `START_${type.toUpperCase()}_LOADING`,
      forced,
      value: type,
    }
  }
  return {
    type: START_LOADING,
    forced,
  }
}

export const stopLoader = (forced = false, type = null) => {
  if (type) {
    return {
      type: `STOP_${type.toUpperCase()}_LOADING`,
      forced,
      value: type,
    }
  }
  return {
    type: STOP_LOADING,
    forced,
  }
}

const loading = (state = { general: true }, action) => {
  switch (action.type) {
    case START_LOADING:
      if (action.forced) forcedLoader = true
      return { general: true }
    case STOP_LOADING:
      if (!action.forced && forcedLoader) {
        return { general: true }
      }
      forcedLoader = false
      return { general: false }
    default:
      return state
  }
}

export const loadingActions = {
  startLoader,
  stopLoader,
}

export default loading
