import TrialPage1 from './containers/SampleTrial1PdfContainer'

export default store => {
  return {
    path: 'sample-trial-1-pdf',
    exact: true,
    getComponent(nextState, cb) {
      require.ensure([], () => {
        cb(null, TrialPage1, 'articles')
      })
    },
  }
}
