import { injectReducers } from 'store/reducers'
import { setSubroute } from 'store/navbar'
// import ProfilePage from './routes/ProfilePage/containers/ProfilePageContainer'
import AssetsPageRoute from './routes/AssetsIndexPage'
import ClientRoute from './routes/Client'

// Sync route definition
export default store => {
  return {
    path: '/assets',
    exact: true,
    getIndexRoute(location, cb) {
      store.dispatch(setSubroute('assets'))
      require.ensure([], () => {
        cb(null, AssetsPageRoute(store))
      })
    },
    getChildRoutes(location, cb) {
      cb(null, ClientRoute(store))
    },
  }
}
