import React from 'react'
import PropTypes from 'prop-types'
import { Container, RectMarker } from 'components/UIElements'
import { COLORS } from 'utils/constants'

const meditationIlloOne = require('../../../assets/go42784/3/meditation_illo_1@3x.png')
const meditationIllTwo = require('../../../assets/go42784/3/meditation_illo_2@3x.png')

const ArticleThree = ({ strings }) => {
  return (
    <div className='article page lavender'>
      <RectMarker color={COLORS.deepLavender} />
      <h1>{strings['article.3.meditation.1-h1']}</h1>
      <p>{strings['article.3.meditation.2-p']}</p>
      <img
        className='dynamic-width'
        alt='meditation-pic-1'
        src={meditationIlloOne}
      />
      <section>
        <h2>{strings['article.3.meditation.3-h2']}</h2>
      </section>
      <Container className='meditation-container-1'>
        <section>
          <p>{strings['article.3.meditation.4-p']}</p>
        </section>
      </Container>
      <Container className='meditation-container-2'>
        <section>
          <p>{strings['article.3.meditation.5-p']}</p>
          <div>
            <img
              className='width-m'
              alt='meditation-pic-2'
              src={meditationIllTwo}
            />
          </div>
        </section>
      </Container>
      <section>
        <small>{strings['article.3.meditation.6-caption']}</small>
      </section>
    </div>
  )
}

ArticleThree.propTypes = {
  strings: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  ),
}

export default ArticleThree
