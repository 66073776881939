import React from 'react'
import PropTypes from 'prop-types'
import '../stylesheets/styles.css'

const VisitArticle = ({ strings }) => {
  return (
    <>
      <div className='e24_132'>
        <div className='e19_25'>
          <div className='e19_26' />
          <div className='e19_27' />
          <span className='e19_28'>VICTORION-2 PREVENT Study</span>
          <span className='e19_29'>
            Eating a healthy and well-balanced diet is a key part of promoting
            heart health. As part of a healthy diet, here is what groups such as
            the American Heart Association and the European Society of
            Cardiology recommend:
          </span>
          <div className='e19_32' />
          <span className='e19_54'>Healthy Diet for a Healthier Heart</span>
          <span className='e19_55'>Lots of:</span>
          <span className='e19_56'>Fruits</span>
          <span className='e19_57'>Vegetables</span>
          <span className='e19_58'>Whole Grains</span>
          <div className='e24_115' />
          <div className='e24_123' />
          <div className='e24_126' />
          <span className='e19_59'>Healthy sources of protein, such as:</span>
          <div className='e24_125' />
          <span className='e19_60'>Lean meats and poultry</span>
          <div className='e24_120' />
          <span className='e19_61'>Fish and other seafood</span>
          <div className='e24_127' />
          <span className='e19_62'>Low-fat or non-fat dairy</span>
          <div className='e24_118' />
          <span className='e19_63'>
            Plant-based proteins like nuts and legumes
          </span>
          <span className='e19_64'>Less (or none) of:</span>
          <div className='e24_119' />
          <span className='e19_65'>Processed foods</span>
          <div className='e24_122' />
          <span className='e19_66'>Sugars</span>
          <div className='e24_121' />
          <span className='e19_67'>Salt</span>
          <div className='e24_116' />
          <span className='e19_68'>Alcohol</span>
          <div className='e19_83' />
          <div className='e19_84' />
          <div className='e24_128'>
            <div className='e19_51' />
            <span className='e19_69'>
              Even when dining out, you can make heart-healthy choices by
              avoiding menu options with lots of sugars, saturated fats, or
              sodium. By making healthier eating choices, you can help promote
              your heart health daily.
            </span>
          </div>
        </div>
      </div>
      <div className='e24_118' />
      <span className='e19_63'>Plant-based proteins like nuts and legumes</span>
      <span className='e19_64'>Less (or none) of:</span>
      <div className='e24_119' />
      <span className='e19_65'>Processed foods</span>
      <div className='e24_122' />
      <span className='e19_66'>Sugars</span>
      <div className='e24_121' />
      <span className='e19_67'>Salt</span>
      <div className='e24_116' />
      <span className='e19_68'>Alcohol </span>
      <div className='e19_83' />
      <div className='e19_84' />
      <div className='e24_128'>
        <div className='e19_51' />
        <span className='e19_69'>
          Even when dining out, you can make heart-healthy choices by avoiding
          menu options with lots of sugars, saturated fats, or sodium. By making
          healthier eating choices, you can help promote your heart health
          daily.
        </span>
      </div>
    </>
  )
}

VisitArticle.propTypes = {
  strings: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  ),
}

export default VisitArticle
