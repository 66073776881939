import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { setLocale } from 'store/locales'
import { authActions } from 'store/user'
import ErrorBox from '../ErrorBox'

import './CoreLayout.scss'

const CoreLayout = props => {
  const { children, direction } = props
  return (
    <div className={`layout-container ${direction}`}>
      <div className='core-layout__viewport non-login'>
        <ErrorBox />
        {children}
      </div>
    </div>
  )
}

const mapStateToProps = ({ locales, strings, user }) => {
  const { direction } = locales
  return {
    direction,
    sessionTimeoutSeconds: user?.study?.session_timeout_seconds,
    strings,
  }
}
const mapDispatchToProps = {
  onLogout: authActions.logout,
  setLocale,
}

export default connect(mapStateToProps, mapDispatchToProps)(CoreLayout)

CoreLayout.propTypes = {
  children: PropTypes.element.isRequired,
  direction: PropTypes.string,
}
