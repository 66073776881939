import { connect } from 'react-redux'
import { selectLanguage } from 'store/locales'
import PdfArticleLandingPage from '../components/PdfArticleLandingPage'

const mapStateToProps = state => {
  const { strings, locales } = state
  const { selectedLocale } = locales
  return {
    selectedLocale,
    strings,
  }
}

const mapDispatchToProps = {
  selectLanguage,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PdfArticleLandingPage)
