import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Container, RectMarker } from 'components/UIElements'
import { COLORS } from 'utils/constants'

const nonIceWaterImg = require('../../../assets/go42784/10/hydration illo@3x.png')
const notificationImg = require('../../../assets/go42784/10/notification icon color 2@3x.png')

const AssetsPage = ({ strings }) => {
  function fillSections(strs) {
    if (strs['article.10.hydration.4-p']) {
      return strs['article.10.hydration.4-p']
        .trim()
        .split('<strong>')
        .filter(el => !!el)
    }
    return []
  }
  const [sections, setSections] = useState(fillSections(strings))

  useEffect(() => {
    setSections(fillSections(strings))
  }, [strings])

  return (
    <div className='article page pink-1'>
      <RectMarker color={COLORS.purple2} />
      <h1>{strings['article.10.hydration.1-h1']}</h1>
      <p>{strings['article.10.hydration.2-p']}</p>
      <img className='dynamic-width' alt='ice-water-img' src={nonIceWaterImg} />
      <Container>
        <p>{strings['article.10.hydration.3-p']}</p>
        {sections.map((section, i) => {
          const newSectionArray = section.split('</strong>')
          return (
            <section
              className={`less-padding ${i === 0 ? 'less-padding-top' : ''}`}
              key={i}>
              <p>
                <b>{newSectionArray[0]}</b>
                <span>{` ${newSectionArray[1].slice(1)}`}</span>
              </p>
            </section>
          )
        })}
      </Container>
      <Container>
        <img className='width-xs' alt='notification' src={notificationImg} />
        <p>{strings['article.10.hydration.5-p']}</p>
      </Container>
      <section>
        <small>{strings['article.10.hydration.6-caption']}</small>
      </section>
    </div>
  )
}

AssetsPage.propTypes = {
  strings: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  ),
}

export default AssetsPage
