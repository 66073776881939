import ArticleTwoPage from './containers/ArticleFiveContainer'

export default store => {
  return {
    path: '5',
    exact: true,
    getComponent(nextState, cb) {
      require.ensure([], () => {
        cb(null, ArticleTwoPage, 'articles')
      })
    },
  }
}
