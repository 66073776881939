/**
 * Created by karan on 4/13/17.
 */

import 'noty/src/noty.scss'

export default props => {
  if (props.noty) {
    props.noty
      .on('onClose', () => {
        props.closeNoty()
      })
      .show()
  }
  return null
}
