import {
  DEFAULT_LANGUAGE_KEY,
  LANGUAGE_ONLY_DEFAULTS_MAP,
} from 'utils/constants'
import { capitalize } from 'utils/misc'

/**
 * This helper func takes the fetched locales list and generates a language map
 * that takes the language key with no country code (e.g. "en", "JP")
 * to return a locale key that the system uses (e.g. "en_US", "jp_JP")
 */
const generateLanguageMap = localesList => {
  const langMap = {}
  localesList.forEach(localeObj => {
    const { id, applanga } = localeObj
    const langKey = id.split('_')[0]
    const modifiedLocale = id.replace('_', '-')
    /**
     * Need to add another set of keys for Safari which uses slightly different
     * casing for the browser detected language, so these will need to be
     * included in the map as well.
     *  */
    const [lang, country] = id.split('_')
    const safariLocale = `${lang}-${country.toLowerCase()}`

    const [applangaLang, applangaCountry] = applanga.split('-')

    let modifiedApplanga = applanga
    if (applangaCountry) {
      /**
       * Applanga keys require the country codes to be uppercase in the API request.
       * In the specific case for Chinese where the dialect/country code is "hans",
       * the dialect portion needs to be sent capitalized, i.e., "zh-Hans".
       * This is why we do a ternary here specific for Chinese.
       *  */

      const modifiedCountry =
        applangaCountry === 'hans'
          ? capitalize(applangaCountry)
          : applangaCountry.toUpperCase()
      modifiedApplanga = `${applangaLang}-${modifiedCountry}`
    }
    langMap[langKey] = {
      id: LANGUAGE_ONLY_DEFAULTS_MAP[langKey]?.id || id,
      applanga:
        LANGUAGE_ONLY_DEFAULTS_MAP[langKey]?.applanga || modifiedApplanga,
    }
    langMap[modifiedLocale] = { id, applanga: modifiedApplanga }
    langMap[safariLocale] = { id, applanga: modifiedApplanga }
    langMap[modifiedApplanga] = { id, applanga: modifiedApplanga }
  })
  return langMap
}

const generateLanguageKeysFromBrowserDetection = localesList => {
  // Browser-detected language
  const detectedLanguage = navigator.language

  // In the case the detected language uses underscores
  const _detectedLanguage = detectedLanguage.replace('_', '-')

  // Just the language portion of the detected language without the country
  const langCode = _detectedLanguage.split('-')[0]
  const langMap = generateLanguageMap(localesList)

  let languageKey
  let applangaKey

  const detectedLangObj = langMap[_detectedLanguage]

  if (detectedLangObj) {
    languageKey = detectedLangObj.id
    applangaKey = detectedLangObj.applanga
  }

  /**
   * We'll default to English if the map which represents our available
   * languages doesn't have the detected language
   * */
  if (!languageKey) languageKey = DEFAULT_LANGUAGE_KEY

  /**
   * If the detected language key is not available on Applanga we will check if
   * just the language key (if there is a country code) is in the dictionary/map,
   * and use just language key for Applanga. If the system doesn't have the detected language
   * in the fetched locales, we fall back to English for the applanga key.
   */

  if (!applangaKey) {
    applangaKey = langMap[langCode]?.applanga || `en`
  }

  return [applangaKey, languageKey]
}

export default generateLanguageKeysFromBrowserDetection
