export const dateInputRegex = /[\d\/]/g
export const dateCharacters = '0123456789/'

export const COLORS = {
  magenta: 'magenta',
  peach: 'peach',
  purple: 'purple',
  purple2: 'purple -p2',
  deepLavender: 'deep-lavender',
  lavender: 'lavender',
  pink3: 'pink p3',
}

export const BROWSER_TYPE_MAP = {
  chrome: 'chrome',
  safari: 'safari',
  firefox: 'firefox',
  edge: 'edge',
  ie: 'IE',
  opera: 'opera',
}

export const DEFAULT_LANGUAGE_KEY = 'en-US'

export const ISO8601_DATETIME_FORMATS = {
  date: 'YYYY-MM-DD',
  datetime: 'YYYY-MM-DD HH:mm',
  time: 'HH:mm',
}

export const RTL_LOCALES = {
  ar: true,
  'ar-IL': true,
  'ar-EG': true,
  he: true,
  ur: true,
}

export const LANGUAGE_ONLY_DEFAULTS_MAP = {
  fr: {
    applanga: 'fr',
    id: 'fr_FR',
  },
  ru: {
    applanga: 'ru-RU',
    id: 'ru_RU',
  },
}

export const LOCALES_LIST = [
  {
    id: 'sv_SE',
    name: 'Swedish (Sweden)',
    localized_name: 'Swedish (Sweden)',
    locale: 'sv_SE',
    applanga: 'sv',
  },
  {
    id: 'it_CH',
    name: 'Italian (Switzerland)',
    localized_name: 'Italian (Switzerland)',
    locale: 'it_CH',
    applanga: 'it-ch',
  },
  {
    id: 'fo_PL',
    name: 'MS100036-0020 - Polish (Poland)',
    localized_name: 'MS100036-0020 - Polish (Poland)',
    locale: 'pl_PL',
    applanga: 'fo',
  },
  {
    id: 'en_US',
    name: 'English (US)',
    localized_name: 'English (US)',
    locale: 'en_US',
    applanga: 'en',
  },
  {
    id: 'lb_IT',
    name: 'MS100036-0020 - Italian (Italy)',
    localized_name: 'MS100036-0020 - Italian (Italy)',
    locale: 'it_IT',
    applanga: 'lb',
  },
  {
    id: 'cy_CN',
    name: 'MS100036-0020 - French (Canada)',
    localized_name: 'MS100036-0020 - French (Canada)',
    locale: 'fr_CA',
    applanga: 'cy',
  },
  {
    id: 'eo_US',
    name: 'MS100036-0020 - English (US)',
    localized_name: 'MS100036-0020 - English (US)',
    locale: 'en_US',
    applanga: 'eo',
  },
  {
    id: 'de_CH',
    name: 'German (Switzerland)',
    localized_name: 'German (Switzerland)',
    locale: 'de_CH',
    applanga: 'de-ch',
  },
  {
    id: 'it_IT',
    name: 'Italian (Italy)',
    localized_name: 'Italian (Italy)',
    locale: 'it_IT',
    applanga: 'it',
  },
  {
    id: 'fr_FR',
    name: 'French (France)',
    localized_name: 'French (France)',
    locale: 'fr_FR',
    applanga: 'fr',
  },
  {
    id: 'ja_JP',
    name: 'Japanese (Japan)',
    localized_name: 'Japanese (Japan)',
    locale: 'ja_JP',
    applanga: 'ja',
  },
  {
    id: 'de_DE',
    name: 'German (Germany)',
    localized_name: 'German (Germany)',
    locale: 'de_DE',
    applanga: 'de',
  },
  {
    id: 'nl_NL',
    name: 'Dutch (Netherlands)',
    localized_name: 'Dutch (Netherlands)',
    locale: 'nl_NL',
    applanga: 'nl',
  },
  {
    id: 'da_DK',
    name: 'Danish (Denmark)',
    localized_name: 'Danish (Denmark)',
    locale: 'da_DK',
    applanga: 'da',
  },
  {
    id: 'es_US',
    name: 'Spanish (US)',
    localized_name: 'Spanish (US)',
    locale: 'es_US',
    applanga: 'es',
  },
  {
    id: 'mi_FR',
    name: 'MS100036-0020 - French (France)',
    localized_name: 'MS100036-0020 - French (France)',
    locale: 'fr_FR',
    applanga: 'mi',
  },
  {
    id: 'qu_EC',
    name: 'MS100036-0020 - German (Germany)',
    localized_name: 'MS100036-0020 - German (Germany)',
    locale: 'de_DE',
    applanga: 'qu-ec',
  },
  {
    id: 'pl_PL',
    name: 'Polish (Poland)',
    localized_name: 'Polish (Poland)',
    locale: 'pl_PL',
    applanga: 'pl',
  },
  {
    id: 'he_IL',
    name: 'Hebrew (Israel)',
    localized_name: 'Hebrew (Israel)',
    locale: 'he_IL',
    applanga: 'he',
  },
  {
    id: 'pt_BR',
    name: 'Portuguese (Brazil)',
    localized_name: 'Portuguese (Brazil)',
    locale: 'pt_BR',
    applanga: 'pt-br',
  },
  {
    id: 'zh_CN',
    name: 'Chinese (China)',
    localized_name: 'Chinese (China)',
    locale: 'zh_CN',
    applanga: 'zh-hans',
  },
  {
    id: 'es_ES',
    name: 'Spanish (Spain)',
    localized_name: 'Spanish (Spain)',
    locale: 'es_ES',
    applanga: 'es-es',
  },
  {
    id: 'en_GB',
    name: 'English (United Kingdom)',
    localized_name: 'English (United Kingdom)',
    locale: 'en_GB',
    applanga: 'en-gb',
  },
  {
    id: 'el_GR',
    name: 'Greek',
    localized_name: 'Greek',
    locale: 'el_GR',
    applanga: 'el',
  },
  {
    id: 'fr_CA',
    name: 'French (Canada)',
    localized_name: 'French (Canada)',
    locale: 'fr_CA',
    applanga: 'fr-ca',
  },
  {
    id: 'de_AT',
    name: 'German (Austria)',
    localized_name: 'German (Austria)',
    locale: 'de_AT',
    applanga: 'de-at',
  },
  {
    id: 'en_AU',
    name: 'English (Australia)',
    localized_name: 'English (AU)',
    locale: 'en_AU',
    applanga: 'en-au',
  },
  {
    id: 'bs_BA',
    name: 'Bosnian (Bosnia)',
    localized_name: 'Bosnian (Bosnia)',
    locale: 'bs_BA',
    applanga: 'bs-ba',
  },
  {
    id: 'ar_EG',
    name: 'Arabic (Egypt)',
    localized_name: 'Arabic (Egypt)',
    locale: 'ar_EG',
    applanga: 'ar-eg',
  },
  {
    id: 'ka_GE',
    name: 'Georgian (Georgia)',
    localized_name: 'Georgian (Georgia)',
    locale: 'ka_GE',
    applanga: 'ka',
  },
  {
    id: 'sw_KE',
    name: 'Kiswahili (Kenya)',
    localized_name: 'Kiswahili (Kenya)',
    locale: 'sw_KE',
    applanga: 'sw',
  },
  {
    id: 'en_ZA',
    name: 'English (South Africa)',
    localized_name: 'English (South Africa)',
    locale: 'en_ZA',
    applanga: 'en-za',
  },
  {
    id: 'tr_TR',
    name: 'Turkish (Turkey)',
    localized_name: 'Turkish (Turkey)',
    locale: 'tr_TR',
    applanga: 'tr',
  },
  {
    id: 'lg_UG',
    name: 'Luganda (Uganda)',
    localized_name: 'Luganda (Uganda)',
    locale: 'lg_UG',
    applanga: 'lg',
  },
  {
    id: 'vi_VN',
    name: 'Vietnamese (Vietnam)',
    localized_name: 'Vietnamese (Vietnam)',
    locale: 'vi_VN',
    applanga: 'vi',
  },
  {
    id: 'es_DO',
    name: 'Spanish (Dominican Republic)',
    localized_name: 'Spanish (Dominican Republic)',
    locale: 'es_DO',
    applanga: 'es-do',
  },
  {
    id: 'en_NZ',
    name: 'English (New Zealand)',
    localized_name: 'English (New Zealand)',
    locale: 'en_NZ',
    applanga: 'en-nz',
  },
  {
    id: 'en_PH',
    name: 'English (Philippines)',
    localized_name: 'English (Philippines)',
    locale: 'en_PH',
    applanga: 'en-ph',
  },
  {
    id: 'es_AR',
    name: 'Spanish (Argentina)',
    localized_name: 'Spanish (Argentina)',
    locale: 'es_AR',
    applanga: 'es-ar',
  },
  {
    id: 'es_CR',
    name: 'Spanish (Costa Rica)',
    localized_name: 'Spanish (Costa Rica)',
    locale: 'es_CR',
    applanga: 'es-cr',
  },
  {
    id: 'ru_RU',
    name: 'Russian (Russia)',
    localized_name: 'Russian (Russia)',
    locale: 'ru_RU',
    applanga: 'ru-ru',
  },
  {
    id: 'hr_HR',
    name: 'Croatian (Croatia)',
    localized_name: 'Croatian (Croatia)',
    locale: 'hr_HR',
    applanga: 'hr-hr',
  },
  {
    id: 'es_GT',
    name: 'Spanish (Guatemala)',
    localized_name: 'Spanish (Guatemala)',
    locale: 'es_GT',
    applanga: 'es-gt',
  },
  {
    id: 'es_CL',
    name: 'Spanish (Chile)',
    localized_name: 'Spanish (Chile)',
    locale: 'es_CL',
    applanga: 'es-cl',
  },
  {
    id: 'es_MX',
    name: 'Spanish (Mexico)',
    localized_name: 'Spanish (Mexico)',
    locale: 'es_MX',
    applanga: 'es-mx',
  },
  {
    id: 'qu_BO',
    name: 'MS100036-0020 - Spanish (Spain)',
    localized_name: 'MS100036-0020 - Spanish (Spain)',
    locale: 'es_ES',
    applanga: 'qu-bo',
  },
  {
    id: 'qu_PE',
    name: 'MS100036-0020 - Russian (Russia)',
    localized_name: 'MS100036-0020 - Russian (Russia)',
    locale: 'ru_RU',
    applanga: 'qu-pe',
  },
  {
    id: 'hu_HU',
    name: 'Hungarian (Hungary)',
    localized_name: 'Hungarian (Hungary)',
    locale: 'hu_HU',
    applanga: 'hu',
  },
  {
    id: 'sr_BA',
    name: 'Serbian (Bosnia)',
    localized_name: 'Serbian (Bosnia)',
    locale: 'sr_BA',
    applanga: 'sr-ba',
  },
  {
    id: 'hr_BA',
    name: 'Croatian (Bosnia) ',
    localized_name: 'Croatian (Bosnia)',
    locale: 'hr_BA',
    applanga: 'hr-ba',
  },
  {
    id: 'ceb_PH',
    name: 'Cebuano (Philippines)',
    localized_name: 'Cebuano (Philippines)',
    locale: 'ceb_PH',
    applanga: 'cb',
  },
  {
    id: 'fil_PH',
    name: 'Tagalog (Philippines)',
    localized_name: 'Tagalog (Philippines)',
    locale: 'fil_PH',
    applanga: 'tl',
  },
  {
    id: 'tl_PH',
    name: 'Tagalog (Philippines)',
    localized_name: 'Tagalog (Philippines)',
    locale: 'fil_PH',
    applanga: 'tl',
  },
  {
    id: 'hil_PH',
    name: 'Hiligaynon (Philippines)',
    localized_name: 'Hiligaynon (Philippines)',
    locale: 'fil_PH',
    applanga: 'hg',
  },
  {
    id: 'il_PH',
    name: 'Ilocano (Philippines)',
    localized_name: 'Ilocano (Philippines)',
    locale: 'fil_PH',
    applanga: 'il',
  },
  {
    id: 'af_ZA',
    name: 'Afrikaans (South Africa)',
    localized_name: 'Afrikaans (South Africa)',
    locale: 'af_ZA',
    applanga: 'af',
  },
  {
    id: 'zu_ZA',
    name: 'Zulu (South Africa)',
    localized_name: 'Zulu (South Africa)',
    locale: 'zu_ZA',
    applanga: 'zu',
  },
  {
    id: 'xh_ZA',
    name: 'Xhosa (South Africa)',
    localized_name: 'Xhosa (South Africa)',
    locale: 'xh_ZA',
    applanga: 'xh',
  },
  {
    id: 'st_ZA',
    name: 'Sesotho (South Africa)',
    localized_name: 'Sesotho (South Africa)',
    locale: 'st_ZA',
    applanga: 'st',
  },
  {
    id: 'tn_ZA',
    name: 'Setswana (South Africa)',
    localized_name: 'Setswana (South Africa)',
    locale: 'tn_ZA',
    applanga: 'tn',
  },
  {
    id: 'be_BY',
    name: 'Belarusian (Belarus)',
    localized_name: 'Belarusian (Belarus)',
    locale: 'be_BY',
    applanga: 'be',
  },
  {
    id: 'ru_BY',
    name: 'Russian (Belarus)',
    localized_name: 'Russian (Belarus)',
    locale: 'ru_BY',
    applanga: 'ru-by',
  },
  {
    id: 'zh_MY',
    name: 'Simplified Chinese (Malaysia)',
    localized_name: 'Simplified Chinese (Malaysia)',
    locale: 'zh_MY',
    applanga: 'zh-my',
  },
  {
    id: 'ru_LV',
    name: 'Russian (Latvia) ',
    localized_name: 'Russian (Latvia)',
    locale: 'ru_LV',
    applanga: 'ru-lv',
  },
  {
    id: 'ru_LT',
    name: 'Russian (Lithuania)',
    localized_name: 'Russian (Lithuania)',
    locale: 'ru_LT',
    applanga: 'ru-lt',
  },
  {
    id: 'ru_UA',
    name: 'Russian (Ukraine)',
    localized_name: 'Russian (Ukraine)',
    locale: 'ru_UA',
    applanga: 'ru-ua',
  },
  {
    id: 'lv_LV',
    name: 'Latvian (Latvia)',
    localized_name: 'Latvian (Latvia)',
    locale: 'lv_LV',
    applanga: 'lv',
  },
  {
    id: 'sv_FI',
    name: 'Swedish (Finland) ',
    localized_name: 'Swedish (Finland) ',
    locale: 'sv_FI',
    applanga: 'sv-fi',
  },
  {
    id: 'uk_UA',
    name: 'Ukrainian (Ukraine)',
    localized_name: 'Ukrainian (Ukraine)',
    locale: 'uk_UA',
    applanga: 'uk',
  },
  {
    id: 'fi_FI',
    name: 'Finnish (Finland)',
    localized_name: 'Finnish (Finland)',
    locale: 'fi_FI',
    applanga: 'fi',
  },
  {
    id: 'fr_BE',
    name: 'French (Belgium)',
    localized_name: 'French (Belgium)',
    locale: 'fr_BE',
    applanga: 'fr-be',
  },
  {
    id: 'bg_BG',
    name: 'Bulgarian',
    localized_name: 'Bulgarian',
    locale: 'bg_BG',
    applanga: 'bg',
  },
  {
    id: 'zh-hant_HK',
    name: 'Traditional Chinese (Hong Kong)',
    localized_name: 'Traditional Chinese (Hong Kong)',
    locale: 'zh-hant_HK',
    applanga: 'zh-hk',
  },
  {
    id: 'zh-hant_TW',
    name: 'Traditional Chinese (Taiwan)',
    localized_name: 'Traditional Chinese (Taiwan)',
    locale: 'zh-hant_TW',
    applanga: 'zh-tw',
  },
  {
    id: 'ms_MY',
    name: 'Malay (Malaysia)',
    localized_name: 'Malay (Malaysia)',
    locale: 'ms_MY',
    applanga: 'ms',
  },
  {
    id: 'pt_PT',
    name: 'Portuguese (Portugal)',
    localized_name: 'Portuguese (Portugal)',
    locale: 'pt_PT',
    applanga: 'pt-pt',
  },
  {
    id: 'sr-latn_RS',
    name: 'Serbian (Serbia)',
    localized_name: 'Serbian (Serbia)',
    locale: 'sr-latn_RS',
    applanga: 'sr',
  },
  {
    id: 'sr_RS',
    name: 'Serbian-Cyrillic (Serbia)',
    localized_name: 'Serbian-Cyrillic (Serbia)',
    locale: 'sr_RS',
    applanga: 'sr',
  },
  {
    id: 'sk_SK',
    name: 'Slovak',
    localized_name: 'Slovak',
    locale: 'sk_SK',
    applanga: 'sk',
  },
  {
    id: 'sl_SI',
    name: 'Slovenian',
    localized_name: 'Slovenian',
    locale: 'sl_SI',
    applanga: 'sl',
  },
  {
    id: 'ta_MY',
    name: 'Tamil (Malaysia)',
    localized_name: 'Tamil (Malaysia) ',
    locale: 'ta_MY',
    applanga: 'ta-my',
  },
  {
    id: 'ru_IL',
    name: 'Russian (Israel)',
    localized_name: 'Russian (Israel)',
    locale: 'ru_IL',
    applanga: 'ru-il',
  },
  {
    id: 'cs_CZ',
    name: 'Czech (Czech Republic)',
    localized_name: 'Czech (Czech Republic)',
    locale: 'cs_CZ',
    applanga: 'cs',
  },
  {
    id: 'en_IE',
    name: 'English (Ireland)',
    localized_name: 'English (Ireland)',
    locale: 'en_IE',
    applanga: 'en-ie',
  },
  {
    id: 'nl_BE',
    name: 'Dutch (Belgium)',
    localized_name: 'Dutch (Belgium)',
    locale: 'nl_BE',
    applanga: 'nl-be',
  },
  {
    id: 'en_CA',
    name: 'English (Canada)',
    localized_name: 'English (Canada)',
    locale: 'en_CA',
    applanga: 'en-ca',
  },
  {
    id: 'mk_MK',
    name: 'Macedonian (North Macedonia)',
    localized_name: 'Macedonian (North Macedonia)',
    locale: 'mk_MK',
    applanga: 'mk',
  },
  {
    id: 'en_HK',
    name: 'English (Hong Kong)',
    localized_name: 'English (Hong Kong)',
    locale: 'en_HK',
    applanga: 'en-hk',
  },
  {
    id: 'fr_CH',
    name: 'French (Switzerland)',
    localized_name: 'French (Switzerland)',
    locale: 'fr_CH',
    applanga: 'fr-ch',
  },
  {
    id: 'ko_KR',
    name: 'Korean (South Korea)',
    localized_name: 'Korean (South Korea)',
    locale: 'ko_KR',
    applanga: 'ko',
  },
  {
    id: 'hi_IN',
    name: 'Hindi (India)',
    localized_name: 'Hindi (India)',
    locale: 'hi_IN',
    applanga: 'hi',
  },
  {
    id: 'te_IN',
    name: 'Telugu (India)',
    localized_name: 'Telugu (India)',
    locale: 'te_IN',
    applanga: 'te',
  },
  {
    id: 'ta_IN',
    name: 'Tamil (India)',
    localized_name: 'Tamil (India)',
    locale: 'ta_IN',
    applanga: 'ta',
  },
  {
    id: 'mr_IN',
    name: 'Marathi (India)',
    localized_name: 'Marathi (India)',
    locale: 'mr_IN',
    applanga: 'mr',
  },
  {
    id: 'or_IN',
    name: 'Oriya (India)',
    localized_name: 'Oriya (India)',
    locale: 'or_IN',
    applanga: 'or',
  },
  {
    id: 'kn_IN',
    name: 'Kannada (India)',
    localized_name: 'Kannada (India)',
    locale: 'kn_IN',
    applanga: 'kn',
  },
  {
    id: 'ur_IN',
    name: 'Urdu (India)',
    localized_name: 'Urdu (India)',
    locale: 'ur_IN',
    applanga: 'ur',
  },
  {
    id: 'lt_LT',
    name: 'Lithuanian (Lithuania)',
    localized_name: 'Lithuanian (Lithuania)',
    locale: 'lt_LT',
    applanga: 'lt',
  },
  {
    id: 'pa-guru_IN',
    name: 'Punjabi (India)',
    localized_name: 'Punjabi (India)',
    locale: 'pa-guru_IN',
    applanga: 'pa',
  },
  {
    id: 'cy_GB',
    name: 'Welsh (Cymraeg)',
    localized_name: 'Welsh (Cymraeg)',
    locale: 'cy_GB',
    applanga: 'cy',
  },
  {
    id: 'ro_RO',
    name: 'Romanian (Romania)',
    localized_name: 'Romanian (Romania)',
    locale: 'ro_RO',
    applanga: 'ro',
  },
  {
    id: 'de_BE',
    name: 'German (Belgium)',
    localized_name: 'German (Belgium)',
    locale: 'de_BE',
    applanga: 'de-be',
  },
  {
    id: 'th_TH',
    name: 'Thai (Thailand)',
    localized_name: 'Thai (Thailand)',
    locale: 'th_TH',
    applanga: 'th',
  },
  {
    id: 'bn_IN',
    name: 'Bengali (India)',
    localized_name: 'Bengali (India)',
    locale: 'bn_IN',
    applanga: 'bn',
  },
  {
    id: 'ml_IN',
    name: 'Malayalam (India)',
    localized_name: 'Malayalam (India)',
    locale: 'ml_IN',
    applanga: 'ml',
  },
  {
    id: 'gu_IN',
    name: 'Gujarati (India)',
    localized_name: 'Gujarati (India)',
    locale: 'gu_IN',
    applanga: 'gu',
  },
  {
    id: 'et_EE',
    name: 'Estonian (Estonia)',
    localized_name: 'Estonian (Estonia)',
    locale: 'et_EE',
    applanga: 'et',
  },
  {
    id: 'ar_IL',
    name: 'Arabic (Israel)',
    localized_name: 'Arabic',
    locale: 'ar_IL',
    applanga: 'ar-il',
  },
  {
    id: 'en_MY',
    name: 'English (Malaysia)',
    localized_name: 'English (Malaysia)',
    locale: 'en_MY',
    applanga: 'en-my',
  },
]
