import ArticleTenPage from './containers/ArticleTenContainer'

export default store => {
  return {
    path: '10',
    exact: true,
    getComponent(nextState, cb) {
      require.ensure([], () => {
        cb(null, ArticleTenPage, 'articles')
      })
    },
  }
}
