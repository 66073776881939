import { connect } from 'react-redux'
import { selectLanguage } from 'store/locales'
import ArticleNine from '../components/ArticleNine'

const mapStateToProps = ({ strings }) => ({ strings })

const mapDispatchToProps = {
  selectLanguage,
}

export default connect(mapStateToProps, mapDispatchToProps)(ArticleNine)
