import PdfArticleLanding from './containers/PdfArticleContainer'

export default store => {
  return {
    path: 'pdf-articles',
    getComponent(nextState, cb) {
      require.ensure([], () => {
        cb(null, PdfArticleLanding, 'articles')
      })
    },
  }
}
