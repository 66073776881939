import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Container, RectMarker } from 'components/UIElements'
import { COLORS } from 'utils/constants'

const notificationImg = require('../../../assets/go42784/9/notification icon color 3@3x.png')
const walkingImg1 = require('../../../assets/go42784/9/walking illo 1@3x.png')
const walkingImg2 = require('../../../assets/go42784/9/walking illo 2@3x.png')
const walkingImg3 = require('../../../assets/go42784/9/walking illo 3@3x.png')

const containerObj = [
  { className: 'width-music', title: '', img: walkingImg1, text: '' },
  { className: 'width-person', title: '', img: walkingImg2, text: '' },
  { className: 'width-people', title: '', img: walkingImg3, text: '' },
]

const AssetsPage = ({ strings }) => {
  function fillContainers(strs) {
    for (let i = 0; i < containerObj.length; i++) {
      const element = containerObj[i]
      element.title = strs[`article.9.activity.${3 + 2 * i}-h3`]
      element.text = strs[`article.9.activity.${4 + 2 * i}-p`]
    }
    return containerObj
  }
  const [containers, setContainers] = useState(fillContainers(strings))

  return (
    <div className='article page lavender'>
      <RectMarker color={COLORS.deepLavender} />
      <h1>{strings['article.9.activity.1-h1']}</h1>
      <section>
        <p>{strings['article.9.activity.2-p']}</p>
      </section>
      {containers.map((container, i) => {
        return (
          <Container key={i}>
            <div className='flexed start-justified bottom-margin'>
              <img
                className={`mr ${container.className}`}
                alt={`img_number${i}`}
                src={container.img}
              />
              <h3>{container.title}</h3>
            </div>
            <p>{container.text}</p>
          </Container>
        )
      })}

      <Container
        className='more-margin-top'
        centerIcon={
          <img className='width-xs' alt='tip-bulb-icon' src={notificationImg} />
        }>
        <p>{strings['article.9.activity.9-p']}</p>
      </Container>
      <section>
        <small>{strings['article.9.activity.10-caption']}</small>
      </section>
    </div>
  )
}

AssetsPage.propTypes = {
  strings: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  ),
}

export default AssetsPage
