import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Container, RectMarker } from 'components/UIElements'
import { COLORS } from 'utils/constants'

const communicatingImg = require('../../../assets/go42784/4/group_93.png')
const number1 = require('../../../assets/go42784/4/number 1@3x.png')
const number2 = require('../../../assets/go42784/4/number 2@3x.png')
const number3 = require('../../../assets/go42784/4/number 3@3x.png')
const number4 = require('../../../assets/go42784/4/number 4@3x.png')
const notificationIcon = require('../../../assets/go42784/4/notification_icon_color_2@3x.png')

const sectionImgs = [number1, number2, number3, number4]

const AssetsPage = ({ strings }) => {
  function fillSectionsArray(strs) {
    const arr = []
    for (let i = 0; i < 4; i++)
      arr.push(strs[`article.4.communicating.${4 + i}-p`])
    return arr
  }
  const [sections, setSections] = useState(fillSectionsArray(strings))

  useEffect(() => {
    setSections(fillSectionsArray(strings))
  }, [strings])

  return (
    <div className='article page pink-1'>
      <RectMarker color={COLORS.purple2} />
      <h1>{strings['article.4.communicating.1-h1']}</h1>
      <section>
        <p>{strings['article.4.communicating.2-p']}</p>
        <img alt='communicating-1' src={communicatingImg} />
        <p>{strings['article.4.communicating.3-p']}</p>
      </section>
      <Container>
        {sections.map((section, i) => {
          return (
            <section key={i} className='no-border'>
              <div className='flexed center-justified bottom-margin'>
                <img
                  className='width-xs'
                  alt={`number${i + 1}`}
                  src={sectionImgs[i]}
                />
                <RectMarker color='divider pink1' />
              </div>
              <p>{section}</p>
            </section>
          )
        })}
      </Container>
      <Container>
        <img className='width-xs' alt='notification' src={notificationIcon} />
        <p>{strings['article.4.communicating.8-p']}</p>
      </Container>
      <section>
        <small>{strings['article.4.communicating.9-caption']}</small>
      </section>
    </div>
  )
}

AssetsPage.propTypes = {
  strings: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  ),
}

export default AssetsPage
