import React from 'react'
import PropTypes from 'prop-types'
import { Container, RectMarker } from 'components/UIElements'
import { COLORS } from 'utils/constants'
import '../../../../../../../../../styles/d3/article.scss'

const water = require('../../../assets/go42784/7/dietitian_illo_1@3x.png')
const burger = require('../../../assets/go42784/7/dietitian_illo_2@3x.png')
const avacado = require('../../../assets/go42784/7/dietitian_illo_3@3x.png')
const steak = require('../../../assets/go42784/7/dietitian_illo_4@3x.png')

const ArticleSeven = ({ strings }) => {
  return (
    <div className='article page purple'>
      <RectMarker color={COLORS.purple} />
      <h1>{strings['article.7.dietitian.1-h1']}</h1>
      <p>
        {
          strings[
            'article.7.dietitian.Throughout_this_clinical_study,_prioritizing_your_health_and_wellbeing_is_key._A_registered_dietitian_shared_how_a_balanced_diet_and_plenty_of_fluids_can_help_you_feel_your_best,_especially_during_treatment._Here_are_four_goals_to_keep_in_mind_each_day:'
          ]
        }
      </p>
      <Container>
        <section className='no-border'>
          <div className='flexed center-justified bottom-margin section-divide'>
            <h1>1</h1>
            <RectMarker color='divider pink1' />
          </div>
          <div className='flexed heading-and-pic'>
            <h3>{strings['article.7.dietitian.2-h2']}</h3>
            <img className='width-s' alt='water-icon' src={water} />
          </div>
          <p>{strings['article.7.dietitian.3-p']}</p>
        </section>
        <section className='no-border'>
          <div className='flexed center-justified bottom-margin section-divide'>
            <h1>2</h1>
            <RectMarker color='divider pink1' />
          </div>
          <div className='flexed heading-and-pic'>
            <h3>{strings['article.7.dietitian.4-h2']}</h3>
            <img className='width-s' alt='burger-icon' src={burger} />
          </div>
          <p>{strings['article.7.dietitian.5-p']}</p>
        </section>
        <section className='no-border'>
          <div className='flexed center-justified bottom-margin section-divide'>
            <h1>3</h1>
            <RectMarker color='divider pink1' />
          </div>
          <div className='flexed heading-and-pic'>
            <h3>{strings['article.7.dietitian.6-h2']}</h3>
            <img className='width-s' alt='avacado-icon' src={avacado} />
          </div>
          <p>{strings['article.7.dietitian.7-p']}</p>
        </section>
        <section className='no-border'>
          <div className='flexed center-justified bottom-margin section-divide'>
            <h1>4</h1>
            <RectMarker color='divider pink1' />
          </div>
          <div className='flexed heading-and-pic'>
            <h3>{strings['article.7.dietitian.8-h2']}</h3>
            <img className='width-s' alt='steak-icon' src={steak} />
          </div>
          <p>{strings['article.7.dietitian.9-p']}</p>
        </section>
      </Container>
      <section>
        <small>{strings['article.7.dietitian.10-caption']}</small>
      </section>
    </div>
  )
}

ArticleSeven.propTypes = {
  strings: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  ),
}

export default ArticleSeven
