// ------------------------------------
// Constants
// ------------------------------------
import request from 'utils/request'
import { getUTCOffset } from 'utils/time'
import { modalActions } from 'store/modal'
export const USER_LOGGED_IN = 'USER_LOGGED_IN'
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT'
export const USER_CONSENTED = 'USER_CONSENTED'
export const USER_CHANGED_PASSWORD = 'USER_CHANGED_PASSWORD'
export const UPDATE_USERNAME = 'UPDATE_USERNAME'
export const SET_USER_IMAGE = 'SET_USER_IMAGE'

function logout() {
  localStorage.removeItem('user')
  return dispatch => {
    dispatch({
      type: USER_LOGGED_OUT,
    })
    dispatch(modalActions.closeModal())
  }
}

function updateUsername(username) {
  return {
    type: UPDATE_USERNAME,
    payload: username,
  }
}

function changedPassword() {
  return {
    type: USER_CHANGED_PASSWORD,
  }
}

export function fetchUserImage(userID) {
  return dispatch => {
    const errorMessage = 'Could not fetch user image'
    return dispatch(
      request({
        method: 'GET',
        url: `/control/assets/profile_pics/${userID}`,
        headerOptions: { 'Content-Type': 'image' },
        errorMessage,
        success: json => {
          dispatch({
            type: SET_USER_IMAGE,
            payload: json.asset,
          })
        },
        fail: () => {}, // console.log('user does not have an image'),
      }),
    )
  }
}

export default function userUpdate(
  state = JSON.parse(localStorage.getItem('user')) || {},
  { type, payload },
) {
  let newState
  switch (type) {
    case UPDATE_USERNAME:
      return Object.assign({}, state, { name: payload })
    case USER_LOGGED_IN:
      return payload
    case USER_LOGGED_OUT:
      return {}
    case USER_CONSENTED:
      newState = Object.assign({}, state, { has_consented: true })
      localStorage.setItem('user', JSON.stringify(newState))
      return newState
    case USER_CHANGED_PASSWORD:
      newState = Object.assign({}, state, { is_temporary_password: false })
      localStorage.setItem('user', JSON.stringify(newState))
      return newState
    case SET_USER_IMAGE:
      return Object.assign({}, state, { userImage: payload })
    default:
      return state
  }
}

export const authActions = {
  logout,
  updateUsername,
  changedPassword,
}
