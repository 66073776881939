import React from 'react'
import PropTypes from 'prop-types'
import { Container, RectMarker } from 'components/UIElements'
import { COLORS } from 'utils/constants'
import '../../../../../../../../../styles/d3/article.scss'

const lightBulb = require('../../../assets/go42784/8/tip_icon_color_6@3x.png')
const mealContainers = require('../../../assets/go42784/8/prep_illo@3x.png')

const ArticleEight = ({ strings }) => {
  return (
    <div className='article page peach'>
      <RectMarker color={COLORS.peach} />
      <h1>{strings['article.8.prep.1-h1']}</h1>
      <p>{strings['article.8.prep.2-p']}</p>
      <Container
        centerIcon={
          <img className='width-xs' alt='tip-bulb-icon' src={lightBulb} />
        }
        className='more-margin-top'>
        <h3>{strings['article.8.prep.3-h3']}</h3>
      </Container>
      <img
        className='dynamic-width ninety'
        alt='meal-container-icon'
        src={mealContainers}
      />
      <p>{strings['article.8.prep.4-p']}</p>
      <Container>
        <p
          dangerouslySetInnerHTML={{ __html: strings['article.8.prep.5-p'] }}
        />
      </Container>
      <p>{strings['article.8.prep.6-p']}</p>
    </div>
  )
}

ArticleEight.propTypes = {
  strings: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  ),
}

export default ArticleEight
