import React from 'react'
import PropTypes from 'prop-types'
import '../stylesheets/styles.css'

const ArticleEight = ({ strings }) => {
  return (
    <div className='e24_130'>
      <div className='e5_33'>
        <div className='e5_34'></div>
        <div className='e5_35'></div>
        <span className='e5_32'>
          VICTORION-2 PREVENT Study 1-Year Anniversary
        </span>
        <div className='e15_50'>
          <div className='e6_8'></div>
          <span className='e6_2'>
            Congratulations! You have reached the 1-year anniversary of
            participating in the VICTORION-2 PREVENT Study.
          </span>
        </div>
        <span className='e15_45'>
          Every study visit that you attend is another step on the path of
          progress toward treatments for cardiovascular disease. By continuing
          to participate in this research study, you are helping scientists
          learn more about cardiovascular disease and how to potentially treat
          it. Thank you for contributing to these efforts.
        </span>
        <div className='e15_47'></div>
        <div className='e15_48'></div>
        <div className='e15_49'></div>
        <div className='e22_101'></div>
      </div>
    </div>
  )
}

ArticleEight.propTypes = {
  strings: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  ),
}

export default ArticleEight
