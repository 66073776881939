import ArticleOnePage from './containers/ArticleOneContainer'

export default store => {
  return {
    path: '1',
    exact: true,
    getComponent(nextState, cb) {
      require.ensure([], () => {
        cb(null, ArticleOnePage, 'articles')
      })
    },
  }
}