import React from 'react'
import PropTypes from 'prop-types'
import { RectMarker } from 'components/UIElements'
import { COLORS } from 'utils/constants'
import '../../../../../../../../../styles/d3/article.scss'

const knowOne = require('../../../assets/go42784/2/know_illo_1@3x.png')
const knowTwo = require('../../../assets/go42784/2/know_illo_2@3x.png')
const knowThree = require('../../../assets/go42784/2/know_illo_3@3x.png')

const AssetsPage = ({ strings }) => {
  return (
    <div className='article page peach'>
      <RectMarker color={COLORS.peach} />
      <h1>{strings['article.2.know.1-h1']}</h1>
      <section className='flexed column start-aligned'>
        <h2 className='contained'>{strings['article.2.know.2-h2']}</h2>
        <p>{strings['article.2.know.3-p']}</p>
        <img className='dynamic-width' alt='know-tip-pic-1' src={knowOne} />
      </section>
      <section className='flexed column start-aligned'>
        <h2 className='contained'>{strings['article.2.know.4-h2']}</h2>
        <p>{strings['article.2.know.5-p']}</p>
        <img className='dynamic-width' alt='know-tip-pic-2' src={knowTwo} />
      </section>
      <section className='flexed column start-aligned'>
        <h2 className='contained'>{strings['article.2.know.6-h2']}</h2>
        <p>{strings['article.2.know.7-p']}</p>
        <img className='dynamic-width' alt='know-tip-pic-3' src={knowThree} />
      </section>
    </div>
  )
}

AssetsPage.propTypes = {
  strings: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  ),
}

export default AssetsPage
