import React from 'react'
import PropTypes from 'prop-types'
import { Container, RectMarker } from 'components/UIElements'
import { COLORS } from 'utils/constants'
import '../../../../../../../../../styles/d3/article.scss'

const lightBulb = require('../../../assets/go42784/5/tip_icon_color_1@3x.png')
const dialogue = require('../../../assets/go42784/5/speaking_illo@3x.png')

const AssetsPage = ({ strings }) => {
  return (
    <div className='article page magenta'>
      <RectMarker color={COLORS.magenta} />
      <h1>{strings['article.5.speaking.1-h1']}</h1>
      <Container>
        <section>
          <p className='has-color'>
            <strong>{strings['article.5.speaking.2-strong']}</strong>
          </p>
          <p>{strings['article.5.speaking.3-p']}</p>
        </section>
        <section>
          <p className='has-color'>
            <b>{strings['article.5.speaking.4-strong']}</b>
          </p>
          <p>{strings['article.5.speaking.5-p']}</p>
        </section>
        <section>
          <p className='has-color'>
            <b>{strings['article.5.speaking.6-strong']}</b>
          </p>
        </section>
      </Container>
      <Container
        className='more-margin-top'
        centerIcon={
          <img className='width-xs' alt='tip-bulb-icon' src={lightBulb} />
        }>
        <p>{strings['article.5.speaking.7-strong']}</p>
      </Container>
      <Container className='flexed column'>
        <p>{strings['article.5.speaking.8-p']}</p>
        <img
          className='dynamic-width seventy'
          alt='dialogue-icon'
          src={dialogue}
        />
        <p>{strings['article.5.speaking.9-p']}</p>
      </Container>
      <section>
        <small>{strings['article.5.speaking.10-caption']}</small>
      </section>
    </div>
  )
}

AssetsPage.propTypes = {
  strings: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  ),
}

export default AssetsPage
