import { connect } from 'react-redux'
import { selectLanguage } from 'store/locales'
import ArticleTwo from '../components/ArticleTwo'

const mapStateToProps = ({ strings }) => {
  return {
    strings,
  }
}

const mapDispatchToProps = {
  selectLanguage,
}

export default connect(mapStateToProps, mapDispatchToProps)(ArticleTwo)
