import { combineReducers } from 'redux'

const ADD_FILTER = 'ADD_FILTER'
const REMOVE_FILTER = 'REMOVE_FILTER'
const CLEAR_FILTERS = 'CLEAR_FILTERS'
const INITIALIZE_CRITERIA = 'INITIALIZE_CRITERIA'
const SET_FILTER_CRITERIA = 'SET_FILTER_CRITERIA'

const addFilter = (key, filter) => {
  return {
    type: ADD_FILTER,
    filter,
    key
  }
}

const removeFilter = key => {
  return {
    type: REMOVE_FILTER,
    key
  }
}

const clearFilters = () => {
  return {
    type: CLEAR_FILTERS
  }
}

const setFilterCriteria = (key, criteria) => {
  return {
    type: SET_FILTER_CRITERIA,
    key,
    criteria
  }
}

const filters = (state = {}, action) => {
  let newState = Object.assign({}, state)
  switch (action.type) {
    case ADD_FILTER:
      newState[action.key] = action.filter
      return newState
    case REMOVE_FILTER:
      delete newState[action.key]
      return newState
    case CLEAR_FILTERS:
      return {}
    default:
      return state
  }
}

const criteria = (state = {}, action) => {
  let newState = Object.assign({}, state)
  switch (action.type) {
    case INITIALIZE_CRITERIA:
      return action.defaults
    case SET_FILTER_CRITERIA:
      newState[action.key] = action.criteria
      return newState
    case REMOVE_FILTER:
      delete newState[action.key]
      return newState
    case CLEAR_FILTERS:
      return {}
    default:
      return state
  }
}

export default combineReducers({
  filters,
  criteria
})

export const actions = {
  addFilter,
  removeFilter,
  setFilterCriteria,
  clearFilters
}
