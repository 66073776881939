import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import '../stylesheets/main.css'
import pdf from '../pdf/Trial2.pdf'

const SampleTrialPdf = ({ strings }) => {
  useEffect(() => {
    window.location = pdf
  }, [])
  return <div className='v14_2'>YOU WILL BE REDIRECTED SHORTLY.</div>
}

SampleTrialPdf.propTypes = {
  strings: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  ),
}

export default SampleTrialPdf
