import { setSubroute } from 'store/navbar'
import { injectReducers } from 'store/reducers'
// import reducer from './modules/FileUpload'
import AssetsPage from './containers/AssetsContainer'

export default store => {
  return {
    exact: true,
    getComponent(nextState, cb) {
      store.dispatch(setSubroute('assets'))
      require.ensure([], () => {
        // injectReducers(store, { files: reducer })
        cb(null, AssetsPage, 'assets')
      })
    },
  }
}
