import React from 'react'
import PropTypes from 'prop-types'
import 'styles/d3/container.scss'

const Container = ({ children, centerIcon, className = '' }) => {
  return (
    <div className={`container ${className}`}>
      {centerIcon && <div className='center-icon'>{centerIcon}</div>}
      {children}
    </div>
  )
}

Container.propTypes = {
  children: PropTypes.node,
  centerIcon: PropTypes.element,
  className: PropTypes.string,
}

export default Container
