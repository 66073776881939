import ArticleSevenPage from './containers/ArticleSevenContainer'

export default store => {
  return {
    path: '7',
    exact: true,
    getComponent(nextState, cb) {
      require.ensure([], () => {
        cb(null, ArticleSevenPage, 'articles')
      })
    },
  }
}
