import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import '../stylesheets/main.css'

const SampleTrial2 = ({ strings }) => {
  return (
    <div className='v14_2'>
      <div className='v10_116' />
      <div className='v7_54' />
      <div className='v9_100' />
      <div className='v10_115' />
      <span className='v6_18'>The ITL-2002-CL-001 Study</span>
      <span className='v6_19'>Phase 1 Screening Period Visit</span>
      <span className='v6_20'>
        You are more than halfway through the screening phase of the study!
        During this phase, you had assessments done to determine if you could
        take part in the study. Assessments included:
      </span>
      <span className='v6_21'>
        Answering questions about your health, medical history, and medication
        use
      </span>
      <span className='v6_23'>
        Providing your HAE attack history and any medications used to treat your
        HAE or symptoms
      </span>
      <span className='v6_24'>Having a physical examination</span>
      <div className='v7_55' />
      <div className='v7_39' />
      <div className='v8_57' />
      <div className='name' />
      <div className='name' />
      <div className='name' />
      <div className='name' />
      <div className='name' />
      <div className='name' />
      <div className='name' />
      <div className='name' />
      <div className='v7_33' />
      <div className='v7_45' />
      <span className='v6_25'>
        Checking vital signs (blood pressure, heart rate, temperature) and
        weight
      </span>
      <div className='v8_75'>
        <div className='v8_67' />
        <div className='v8_70' />
        <div className='name' />
      </div>
      <span className='v6_26'>
        Measuring the electrical activity in your heart using an
        electrocardiogram (ECG) machine
      </span>
      <div className='v8_76' />
      <span className='v6_27'>
        Providing blood and urine samples for testing
      </span>
      <div className='v8_78' />
      <span className='v6_28'>
        Having a blood pregnancy test (if you are a woman of childbearing
        potential)
      </span>
      <div className='v8_77' />
      <span className='v6_29'>COVID-19 testing</span>
      <span className='v6_30'>
        Completing the MOXIE angioedema Quality of Life questionnaire
      </span>
      <div className='v8_59' />
      <span className='v6_31'>
        The study team provided you with access to your Attack Diary to record
        details about the HAE attacks you experience throughout the study and
        showed you how to use it to make entries.
      </span>
      <span className='v6_32'>
        As you get closer to the end of the screening period, please continue to
        complete the daily diary. Do not hesitate to ask your study doctor and
        study team about any questions you may have as they will be happy to
        answer them.
      </span>
      <div className='v7_37' />
      <div className='v14_38'>
        <div className='v14_7' />
        <div className='v14_8' />
        <div className='v14_10' />
        <div className='v14_11' />
        <div className='v14_12' />
        <div className='v14_13' />
        <div className='v14_14' />
        <div className='v14_15' />
        <div className='v14_16' />
        <div className='v14_17' />
        <div className='v14_18' />
        <div className='v14_19' />
        <div className='v14_20' />
        <div className='v14_21' />
        <div className='v14_22' />
        <div className='v14_23' />
        <div className='v14_24' />
        <div className='v14_25' />
        <div className='v14_26' />
        <div className='v14_27' />
        <div className='v14_28' />
        <div className='name' />
        <div className='v14_31' />
        <div className='v14_37' />
      </div>
      <div className='v14_58'>
        <div className='v14_56' />
        <div className='v14_40' />
        <div className='v14_41' />
        <div className='name' />
        <div className='v14_53' />
        <div className='v14_55' />
      </div>
      <div className='v16_62' />
    </div>
  )
}

SampleTrial2.propTypes = {
  strings: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  ),
}

export default SampleTrial2
