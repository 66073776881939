import { LOCATION_CHANGE } from './location'

export const UPDATE_SEARCH_TERM = 'UPDATE_SEARCH_TERM'

export const updateSearchTerm = searchTerm => {
  return {
    type: UPDATE_SEARCH_TERM,
    searchTerm
  }
}

function searchTerm(state = '', action) {
  switch (action.type) {
    case UPDATE_SEARCH_TERM:
      return action.searchTerm
    case LOCATION_CHANGE:
      return ''
    default:
      return state
  }
}

export default searchTerm
