import ArticleThreePage from './containers/ArticleThreeContainer'

export default store => {
  return {
    path: '3',
    exact: true,
    getComponent(nextState, cb) {
      require.ensure([], () => {
        cb(null, ArticleThreePage, 'articles')
      })
    },
  }
}