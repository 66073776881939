import ArticleTwoPage from './containers/ArticleTwoContainer'

export default store => {
  return {
    path: '2',
    exact: true,
    getComponent(nextState, cb) {
      require.ensure([], () => {
        cb(null, ArticleTwoPage, 'articles')
      })
    },
  }
}