// We only need to import the modules necessary for initial render
import { stringsActions, parseLocale } from 'store/strings'
import { fetchLocales, setDirection, setLocale } from 'store/locales'
import { RTL_LOCALES } from 'utils/constants'
import generateLanguageKeys from 'utils/localization'
import { getQueryParams } from 'utils/misc'
import CoreLayout from '../layouts/CoreLayout/CoreLayout'

import AssetsRoute from './Assets'

async function fetchLocalesList(fetch) {
  await fetch()
}

export const createRoutes = store => {
  return {
    path: '/',
    component: CoreLayout,
    indexRoute: { onEnter: (nextState, replace) => replace('/assets') },
    getChildRoutes(location, cb) {
      fetchLocalesList(() => store.dispatch(fetchLocales())).then(() => {
        const state = store.getState()
        const { locales, location: _location } = state
        const localLocalizationData = JSON.parse(
          localStorage.getItem('localization'),
        )

        // Generate relevant keys from language detected from browser with helper
        const [applangaKey, languageKey] = generateLanguageKeys(
          locales?.localesList,
        )

        // if (!strings[Object.keys(strings)[0]]) {
        if (localLocalizationData) {
          /**
           * We will first look to see if there is a locally stored language preference that
           * the participant has selected. If there is, we default to that language.
           */
          const { selectedLanguage } = localLocalizationData
          const selectedApplanga = parseLocale(selectedLanguage)
          store.dispatch(
            stringsActions.onFetchApplangaStrings(selectedApplanga),
          )
          store.dispatch(setLocale(selectedLanguage))
        } else {
          /**
           * If we do not see a selected preferred language that was stored from a previous session,
           * we will default the app to the detected language from the browser.
           */
          const localeParams = getQueryParams({
            location: _location,
            str: 'locale',
          })
          const defaultLangParam = getQueryParams({
            location: _location,
            str: 'def_lang',
          })

          let applangaReqKey = applangaKey
          let finalLangKey = languageKey

          /**
           * If the detected browser language is included in the given locales in the URL,
           * make the Applanga request with the detected languaged. Otherwise, we make the request
           * with either the default language param or the first language key in the locale params.
           */
          if (localeParams.length || defaultLangParam.length) {
            if (!localeParams.includes(applangaKey)) {
              applangaReqKey = defaultLangParam[0] || localeParams[0]
            }
            if (!localeParams.includes(languageKey)) {
              finalLangKey = defaultLangParam[0] || localeParams[0]
            }
          }

          store.dispatch(stringsActions.onFetchApplangaStrings(applangaReqKey))
          store.dispatch(setLocale(finalLangKey))
          store.dispatch(setDirection(RTL_LOCALES[applangaReqKey]))
        }
      })
      cb(null, [AssetsRoute(store)])
    },
    store,
  }
}

export default createRoutes
