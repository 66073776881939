export const OPEN_MODAL = 'OPEN_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'

function openModal(modalProps) {
  return {
    type: OPEN_MODAL,
    modalProps,
  }
}

function closeModal() {
  return {
    type: CLOSE_MODAL,
  }
}

export default function modalReducer(state = { open: false }, action) {
  switch (action.type) {
    case OPEN_MODAL:
      const defaults = {
        confirmButton: 'Confirm',
        cancelButton: 'Cancel',
      }
      return Object.assign(
        defaults,
        { open: true, className: 'universal-modal' },
        action.modalProps,
      )
    case CLOSE_MODAL:
      return { open: false }
    default:
      return state
  }
}

export const modalActions = {
  openModal,
  closeModal,
}
