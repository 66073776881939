import { combineReducers } from 'redux'
// import request from 'utils/request'
import { stringsActions } from 'store/strings'
import { LOCALES_LIST } from '../utils/constants'

//
// Actions
//

const SET_LOCALES = 'SET_LOCALES'
const SET_LOCALE = 'SET_LOCALE'
const SET_DIRECTION = 'SET_DIRECTION'

//
// Action creators
//

export const setLocales = locales => {
  return {
    type: SET_LOCALES,
    locales,
  }
}
export const setLocale = locale => {
  return {
    type: SET_LOCALE,
    locale,
  }
}
export const setDirection = (isRTL = false) => {
  return {
    type: SET_DIRECTION,
    direction: isRTL ? 'rtl' : 'ltr',
  }
}

//
// Util functions
//

export const selectLanguage = languageItem => dispatch => {
  localStorage.setItem(
    'localization',
    JSON.stringify({
      selectedLanguage: languageItem.key,
    }),
  )
  dispatch(setLocale(languageItem.key))
  dispatch(stringsActions.onFetchApplangaStrings(languageItem.applanga))
}

const getLocaleOptions = locales => {
  const list = locales
    ? locales
        .map(option => {
          const { applanga, id, locale, name } = option
          return {
            key: id,
            text: name,
            value: id,
            id,
            locale,
            applanga,
          }
        })
        .sort((a, b) => a.text.localeCompare(b.text))
    : []
  return list
}

//
// Fetch functions
//

export const fetchLocales = () => {
  return dispatch => {
    return dispatch({
      type: SET_LOCALES,
      locales: getLocaleOptions(LOCALES_LIST),
    })
    // const success = payload => {
    //   dispatch({
    //     type: SET_LOCALES,
    //     locales: getLocaleOptions(payload.languages),
    //   })
    // }
    // return dispatch(
    //   request({
    //     method: 'GET',
    //     url: '/control/locales',
    //     success,
    //   }),
    // )
  }
}

//
// Reducers
//

const localesList = (state = [], action) => {
  switch (action.type) {
    case SET_LOCALES:
      return action.locales
    default:
      return state
  }
}
const selectedLocale = (state = 'en_US', action) => {
  switch (action.type) {
    case SET_LOCALE:
      return action.locale
    default:
      return state
  }
}

const direction = (state = 'ltr', action) => {
  switch (action.type) {
    case SET_DIRECTION:
      return action.direction
    default:
      return state
  }
}

export default combineReducers({
  direction,
  localesList,
  selectedLocale,
})
