import React from 'react'
import PropTypes from 'prop-types'
import { Container, RectMarker } from 'components/UIElements'
import { COLORS } from 'utils/constants'
import '../../../../../../../../../styles/d3/article.scss'

const lightBulb = require('../../../assets/go42784/1/tip_icon_color_5@3x.png')
const notification = require('../../../assets/go42784/1/notification_icon_color_5@3x.png')
const checklist = require('../../../assets/go42784/1/checklist@3x.png')
const meds = require('../../../assets/go42784/1/meds@3x.png')
const discussion = require('../../../assets/go42784/1/disussion@3x.png')
const book = require('../../../assets/go42784/1/book@3x.png')
const shirt = require('../../../assets/go42784/1/shirt@3x.png')

const AssetsPage = ({ strings }) => {
  return (
    <div className='article page purple'>
      <RectMarker color={COLORS.purple} />
      <h1>{strings['article.1.upcoming.1-h1']}</h1>
      <Container
        centerIcon={
          <img className='width-xs' alt='tip-bulb-icon' src={lightBulb} />
        }>
        {strings['article.1.upcoming.2-p']}
      </Container>
      <p>{strings['article.1.upcoming.3-p']}</p>
      <Container>
        <section className='flexed start-aligned start-justified'>
          <img className='left width-s' alt='checklist-icon' src={checklist} />
          <p>{strings['article.1.upcoming.4-p']}</p>
        </section>
        <section className='flexed start-aligned start-justified'>
          <img className='left width-s' alt='meds-icon' src={meds} />
          <p>{strings['article.1.upcoming.5-p']}</p>
        </section>
        <section className='flexed start-aligned start-justified'>
          <img
            className='left width-s'
            alt='discussion-icon'
            src={discussion}
          />
          <p>{strings['article.1.upcoming.6-p']}</p>
        </section>
        <section className='flexed start-aligned start-justified'>
          <img className='left width-s' alt='book-icon' src={book} />
          <p>{strings['article.1.upcoming.7-p']}</p>
        </section>
        <section className='flexed start-aligned start-justified'>
          <img className='left width-s' alt='shirt-icon' src={shirt} />
          <p>{strings['article.1.upcoming.8-p']}</p>
        </section>
      </Container>
      <Container>
        <h2
          className={`${COLORS.purple} flexed center-aligned start-justified`}>
          <img
            className='width-xs'
            alt='notification-icon'
            src={notification}
          />
          {strings['article.1.upcoming.9-h2']}
        </h2>
        <p>{strings['article.1.upcoming.10-p']}</p>
      </Container>
      <p>{strings['article.1.upcoming.11-p']}</p>
    </div>
  )
}

AssetsPage.propTypes = {
  strings: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  ),
}

export default AssetsPage
