import { actions as notyActions } from 'layouts/ErrorBox'

export const isNumeric = str => {
  return !isNaN(parseFloat(str)) && isFinite(str)
}

export const pluralize = (num, singular, plural, includeNum = true) => {
  return `${includeNum ? `${num} ` : ''}${
    num === 0 || num > 1 ? plural : singular
  }`
}

export const validatePassword = str => {
  const isEightCharacters = /^.{8,}$/g
  const hasUppercase = /^(.*[A-Z].*)$/g
  const hasLowercase = /^(.*[a-z].*)$/g
  const hasNumber = /^(.*\d.*)$/g

  return (
    !!str.match(isEightCharacters) &&
    !!str.match(hasUppercase) &&
    !!str.match(hasLowercase) &&
    !!str.match(hasNumber)
  )
}

export const addErrorClass = elementIds => {
  document.querySelectorAll(`#${elementIds.join(', #')}`).forEach(element => {
    element.classList.add('has-error')
  })
}

export const PW_REQUIREMENTS = [
  '8 characters minimum',
  'One uppercase letter',
  'One lowercase letter',
  'One number',
]

export const _secondsToTimeString = seconds => {
  if (isNaN(seconds)) return ''
  const breaks = [60, 60, 24, 7]
  const strings = ['second', 'minute', 'hour', 'day', 'week']
  let time = seconds
  let i
  for (i = 0; i < breaks.length; i++) {
    if (time >= breaks[i]) time /= breaks[i]
    else break
  }
  time = Math.floor(time)
  time = `${time} ${strings[i]}${time > 1 ? 's' : ''}`
  return time
}

export const runNTimes = (num, cb) => {
  for (let i = 0; i < num; i++) {
    cb()
  }
}

/**
 * Downloads the file on the user's browser
 * @param {blob} blob
 * @param {string} name
 */

export function downloadBlob(blob, name) {
  const url = URL.createObjectURL(blob)
  const element = document.createElement('a')
  element.setAttribute('href', url)
  element.setAttribute('target', '_blank')
  element.setAttribute('download', name)
  element.style.display = 'none'
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
  URL.revokeObjectURL(url)
}

export const _getNavBarOffset = () => {
  const navbar = document.getElementsByClassName('navbar')[0]
  const offset = navbar ? navbar.offsetHeight : 0
  return offset
}

// this function calculates offsets for sticky elements for use in componenetDidMount
export const calculateOffsets = (className = 'sticky') => {
  const offsetArr = []
  const navBarOffset = _getNavBarOffset() || 60
  let totalPrevOffsets = 0
  const elements = document.getElementsByClassName(className)
  const elementsArr = [...elements]
  elementsArr.forEach((el, i) => {
    if (i === 0) {
      offsetArr.push(navBarOffset)
      totalPrevOffsets += navBarOffset
    } else {
      const iterOffset = elementsArr[i - 1].offsetHeight + totalPrevOffsets
      offsetArr.push(iterOffset)
      totalPrevOffsets = iterOffset
    }
  })
  return offsetArr
}

// Remove extra newline from rich text editor output in admin panel
const removeExtraNewline = html => {
  return html.replace(/<br\/><br><br\/>/g, '<br/><br>')
}

export const formatInnerHTML = html => {
  return removeExtraNewline(html)
}

export const replaceInString = (string, stringToReplace, newString) => {
  if (!string) return ''
  return string.replace(stringToReplace, newString)
}

export const capitalize = string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const getQueryParams = ({ location = {}, str }) => {
  const search = location.search
  return new URLSearchParams(search).getAll(str)
}
