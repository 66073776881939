import React from 'react'
import PropTypes from 'prop-types'
import 'styles/d3/loader.scss'

const D3Loader = props => {
  const { size = 50, inContainer, containerStyle } = props
  const style = {
    height: size,
    width: size,
    border: `${size * 0.1}px solid #f3f3f3`,
    borderTop: `${size * 0.1}px solid #183b64`,
  }

  return (
    <div
      style={containerStyle}
      className={`d3-loader-container${inContainer ? '' : ' page'}`}
    >
      <div style={style} className='loader' />
    </div>
  )
}

export default D3Loader

D3Loader.propTypes = {
  size: PropTypes.number,
  inContainer: PropTypes.bool,
  containerStyle: PropTypes.object,
}
