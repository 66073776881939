import request from 'utils/request'
import moment from 'moment'

const DEFAULT_STRINGS = require('../strings/en.json')

// ------------------------------------
// Constants
// ------------------------------------

const SET_STRINGS = 'SET_STRINGS'
const DEFAULT_LANGUAGE = 'en'

// ------------------------------------
// Actions
// ------------------------------------

const setStrings = payload => {
  return {
    type: SET_STRINGS,
    payload,
  }
}

// ------------------------------------
// Utils
// ------------------------------------

const getStringValues = strings => {
  const keys = Object.keys(strings)
  const output = {}
  keys.forEach(key => {
    output[key] = strings[key].v
  })
  return output
}

//
// Parses locale to language parameter used by Applanga
// ex) 'en_US' to 'en'
//
const splitLocale = locale =>
  locale.includes('_') ? locale.split('_')[0] : locale

export const parseLocale = locale => {
  if (Array.isArray(locale)) {
    return locale.map(localeItem => splitLocale(localeItem)).join()
  }
  return splitLocale(locale)
}

// ------------------------------------
// Action Creators
// ------------------------------------

const onFetchApplangaStrings = (language = DEFAULT_LANGUAGE) => {
  return dispatch => {
    const { APPLANGA_AUTHORIZATION_HEADER } = process.env
    const appId = APPLANGA_AUTHORIZATION_HEADER
      ? APPLANGA_AUTHORIZATION_HEADER.split('!')[0]
      : ''
    if (!appId) {
      dispatch(setStrings(DEFAULT_STRINGS))
      return
    }
    let languageParam = language
    if (language !== DEFAULT_LANGUAGE) {
      languageParam = parseLocale(language)
    }

    dispatch(
      request({
        method: 'GET',
        url: `https://api.applanga.com/v1/api?app=${appId}&requestedGroups=[%22main%22]&requestedLanguages=[%22${languageParam}%22]`,
        hasLoader: true,
        headerOptions: {
          Authorization: `Bearer ${APPLANGA_AUTHORIZATION_HEADER}`,
        },
        authRequired: false,
        success: json => {
          const { data } = json
          const isResponseEmpty = Object.values(data).length === 0
          if (!isResponseEmpty) {
            moment.locale(languageParam)
          }
          const strings = isResponseEmpty
            ? DEFAULT_STRINGS
            : getStringValues(data[language]?.main.entries)
          dispatch(setStrings(strings))
        },
      }),
    )
  }
}

// ------------------------------------
// Reducer
// ------------------------------------
// const stringKeys = Object.keys(DEFAULT_STRINGS)
// const initialState = {}
// stringKeys.forEach(key => {
//   initialState[key] = ''
// })

const stringsReducer = (state = DEFAULT_STRINGS, action) => {
  switch (action.type) {
    case SET_STRINGS: {
      return action.payload || DEFAULT_STRINGS
    }
    default:
      return state
  }
}

export const stringsActions = {
  onFetchApplangaStrings,
}

export default stringsReducer
