import React from 'react'
import PropTypes from 'prop-types'
import '../stylesheets/styles.css'

const ArticleSample = ({ strings }) => {
  return (
    <div className='e24_131'>
      <div className='e9_2'>
        <div className='e9_3' />
        <div className='e9_4' />
        <span className='e9_5'>VICTORION-2 PREVENT Study</span>
        <span className='e13_2'>
          Thank you for your participation in the VICTORION-2 PREVENT Study!
          Your first treatment visit, called your “baseline”study visit, is
          today.
        </span>
        <div className='e14_9' />
        <div className='e14_10' />
        <span className='e13_3'>At this study visit, you can expect to:</span>
        <span className='e14_6'>How you are feeling</span>
        <span className='e14_5'>Your health</span>
        <span className='e14_7'>
          Whether you have experienced any cardiovascular events since your last
          visit
        </span>
        <span className='e15_14'>Answer questions about:</span>
        <span className='e15_18'>
          Discuss your current and previous medications
        </span>
        <span className='e15_19'>Give blood samples</span>
        <span className='e15_20'>
          Get information from your study doctor about how your lifestyle can
          impact your heart health
        </span>
        <span className='e15_22'>
          Receive a dose of study drug or placebo as an injection under the skin
          of the thigh, abdomen, or upper arm
        </span>
        <span className='e15_52'>Baseline Visit, Study Day 1</span>
        <div className='e23_106' />
        <div className='e23_107' />
        <div className='e23_108' />
        <div className='e15_16' />
        <div className='e17_53' />
        <div className='e17_54' />
        <div className='e23_109' />
        <div className='e23_110' />
        <div className='e15_51' />
        <div className='e15_44'>
          <div className='e15_40' />
          <span className='e15_41'>
            If you have any questions, please reach out to your site team at any
            time!
          </span>
          <div className='e15_42' />
        </div>
        <div className='e19_96' />
      </div>
    </div>
  )
}

ArticleSample.propTypes = {
  strings: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  ),
}

export default ArticleSample
